import { useEffect, useReducer, useState } from 'react'
import './App.css'
import Transport from './Transport'

let committed = false
const transport = new Transport()

const initialBet = {
  id: null,
  amount: 100,
  autoCashOutAmount: null,
  yield: -1,
}
const initialState = {
  bet: null,
  betId: null,
  yield: null,
  result: 1,
  status: 'loading',
  statusText: null,
  allowAutoCashOut: false,
  allowSecondBet: false,
  wallet: 0,
  playerId: null,
  token: null,
  currency: null,
  time: 0,
  timeout: 0,
  gameId: null,
  scoreboard: [],
  minBet: 0,
  maxBet: 0,
  maxPayout: 0,
  bets: [initialBet],
  speed: null,
}

function reducer(state, { type, payload }) {
  switch (type) {
    case 'player/join':
      const bets = payload.allowSecondBet ? [initialBet, initialBet] : [initialBet]
      const scoreboard = payload.bets.map(cur => {
        const next = { ...cur }

        next.playerId = payload.playerId
        next.betId = cur.id
        next.currency = payload.currency
        delete next.id

        return next
      })
      return { ...state, ...payload, scoreboard, bets,  }
    case 'bet/commit': {
      const next = Array.from(state.bets)
      const prev = { ...state.bets[payload.index] }
      prev.yield = payload.yield
      next[payload.index] = prev

      return { ...state, bets: next }
    }
    case 'bet/unset': {
      const next = Array.from(state.bets)
      const prev = { ...next[payload.index] }
      next[payload.index] = { ...initialBet, autoCashOutAmount: prev.autoCashOutAmount }
      return { ...state, bets: next }
    }
    case 'bet/set': {
      const next = Array.from(state.bets)
      const prev = { ...state.bets[payload.index] }
      prev.id = payload.betId
      prev.amount = payload.amount
      next[payload.index] = prev
      return { ...state, bets: next }
    }
    case 'game/idle': {
      const initialBets = state.allowSecondBet ? [initialBet, initialBet] : [initialBet]
      return {
        ...state,
        status: 'idle',
        time: payload.time,
        result: 1,
        yield: null,
        gameId: null,
        scoreboard: [],
        bets: initialBets.map((cur, i) => ({ ...cur, autoCashOutAmount: state.bets[i].autoCashOutAmount }))
      }
    }
    case 'game/start':
      return { ...state, ...payload, status: 'start', result: 1 }
    case 'game/run':
      return { ...state, status: 'run', time: payload.time, gameSpeed: payload.gameSpeed }
    case 'game/stop': {
      return { ...state, status: 'stop', result: payload.result, time: payload.time, timeout: payload.timeout }
    }
    case 'amount/inc': {
      const prev = { ...state.bets[payload.index] }
      prev.amount += 100
      const next = Array.from(state.bets)
      next[payload.index] = prev
      return { ...state, bets: next }
    }
    case 'amount/decr': {
      const prev = { ...state.bets[payload.index] }
      prev.amount = Math.max(prev.amount - 100, 100)
      const next = Array.from(state.bets)
      next[payload.index] = prev
      return { ...state, bets: next }
    }
    case 'bet/authCashOutChange': {
      const prev = { ...state.bets[payload.index] }
      prev.autoCashOutAmount = payload.autoCashOutAmount
      const next = Array.from(state.bets)
      next[payload.index] = prev
      return { ...state, bets: next }
    }
    case 'close': {
      return { ...state, status: 'loading', statusText: 'Session closed' }
    }
    case 'state':
      return { ...state, ...payload }
    case 'bets/update': {
      return {
        ...state,
        scoreboard: state.scoreboard.map(cur => cur.betId === payload.betId ? { ...cur, ...payload } : cur),
        bets: state.bets.map(cur => cur.id === payload.betId ? { ...cur, yield: payload.yield } : cur),
      }
    }
    case 'bets/remove':
      return { ...state, scoreboard: state.scoreboard.filter(cur => cur.betId !== payload.betId) }
    case 'bets/add':
      return { ...state, scoreboard: state.scoreboard.concat(payload) }
    default:
      return state
  }
}

function App() {
  const [state, dispatch] = useReducer(reducer, initialState)

  // console.log(state)

  useEffect(() => {
    const query = new URLSearchParams(window.location.search.substring(1))
    const playerId = query.get('userId')
    const token = query.get('token')

    if (!playerId || !token) {
      return
    }

    transport.connect({ url: process.env.REACT_APP_WS_SERVER_URL, playerId, token })
    // transport.connect({ url: 'ws://192.168.0.107:9502', playerId, token })
      .then((socket) => {
        socket.addEventListener('close', (e) => {
          dispatch({ type: 'close' })
        })

        transport.send('player/join').then(payload => {
          dispatch({ type: 'player/join', payload: { ...payload, playerId, token } })

          transport.addEventListener(event => {
            const { name, data } = event

            dispatch({ type: name, payload: data })

            switch (name) {
              case' game/stop':
                committed = false
                break
              case 'game/idle':
                transport.send('player/status').then(payload => {
                  dispatch({ type: 'state', payload })
                })
                break
              default:
                // nope
            }

          })
        })
      })
  }, [])

  useEffect(() => {
    let timerId

    switch (state.status) {
      case 'stop':
      case 'start':
        timerId = setTimeout(() => {
          const now = Date.now()
          const timeout = Math.max((state.timeout - (now - state.time)), 0)
          dispatch({ type: 'state', payload: { time: now, timeout } })
        }, 100)
        break
      case 'run':
        if (!state.gameSpeed) {
          return
        }

        timerId = setTimeout(() => {
          const now = Date.now()
          const time = (now - state.time) / 1000
          const [a, b] = state.gameSpeed
          const result = Math.round(Math.max(1, a * Math.exp(b * time)) * 100) / 100

          dispatch({ type: 'state', payload: { result } })
        }, 100)
        break
      default:
        // skip
    }

    return () => {
      clearTimeout(timerId)
    }
  })

  if ('loading' === state.status || 'tick' === state.status) {
    return (
      <div className="App">
        <div className="Placeholder">{state.statusText || 'Loading...'}</div>
      </div>
    )
  }

  return (
    <div className="App">
      <div className="App-player">
        <div># {state.playerId}</div>
        <div>{money(state.wallet, state.currency)}</div>
      </div>
      <div className="Grid-root">
        <div className="Grid-bets">
          <Scoreboard state={state}/>
        </div>
        <div className="Grid-canvas">
          <div className="Status-root">
            <Status state={state}/>
          </div>
          {state.bets.map((cur, index) => (
            <div className="Control-root" key={index}>
              <Button
                allowAutoCashOut={state.allowAutoCashOut}
                state={state.bets[index]}
                result={state.result}
                status={state.status}
                onBetSet={() => handleBetSet({ ...state.bets[index], index })}
                onBetUnset={() => handleBetUnset({ ...state.bets[index], index })}
                onCashOut={() => handleCashOut({ ...state.bets[index], index })}
                onAmountInc={() => handleAmountInc(index)}
                onAmountDecr={() => handleAmountDecr(index)}
                onAutoCashOutChange={(payload) => handleAutoCashOutChange({ ...payload, index })}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  )


  function handleAmountInc(index) {
    dispatch({ type: 'amount/inc', payload: { index } })
  }

  function handleAmountDecr(index) {
    dispatch({ type: 'amount/decr', payload: { index } })
  }

  function handleBetSet(payload) {
    transport.send('bet/set', { autoCommit: payload.autoCashOutAmount, gameId: state.gameId, amount: payload.amount }).then(res => {
    // transport.send('bet/set', { autoCommit: , gameId: state.gameId, amount: payload.amount }).then(res => {
      dispatch({ type: 'bet/set', payload: { ...res, index: payload.index, } })
    })
  }

  function handleBetUnset({ index, id: betId, }) {
    transport.send('bet/unset', { gameId: state.gameId, betId }).then(payload => {
      dispatch({ type: 'bet/unset', payload: { ...payload, index } })
    })
  }

  function handleCashOut({ index, id: betId }) {
    transport.send('bet/commit', { gameId: state.gameId, betId }).then(payload => {
      dispatch({ type: 'bet/commit', payload: { ...payload, index } })
    })
  }

  function handleAutoCashOutChange(payload) {
    dispatch({ type: 'bet/authCashOutChange', payload })
  }
}

function Status(props) {
  const { state } = props

  switch (state.status) {
    case 'idle':
      return <div className="Status-text">Waiting for bets</div>
    case 'start':
      return (
        <div className="Status-text">
          <div>Starts in:</div>
          <div>{(state.timeout / 1000).toFixed(2)}s</div>
        </div>
      )
    case 'run':
      return (
        <div className="Status-text">
          <div className="Status-result">{state.result.toFixed(2)}x</div>
        </div>
      )
    case 'stop':
      return (
        <div className="Status-text done">
          <div className="Status-result">{state.result.toFixed(2)}x</div>
          <div>Next round: {(state.timeout / 1000).toFixed(2)}s</div>
        </div>
      )
    default:
      return null
  }
}

function Scoreboard(props) {
  const { state } = props

  const children = state.scoreboard.map((cur, i) => {
    return (
      <tr key={cur.betId} className={cur.yield > 0 ? 'cashout' : null}>
        <td>{cur.playerId}</td>
        <td>{money(cur.amount, cur.currency)}</td>
        <td>{cur.yield ? cur.yield.toFixed(2) : '-'}</td>
        <td>{cur.payout ? money(cur.payout, cur.currency) : '-'}</td>
      </tr>
    )
  })

  return (
    <table className="Bets-table">
      <colgroup>
        <col style={{ width: '25%' }}/>
        <col style={{ width: '25%' }}/>
        <col style={{ width: '25%' }}/>
        <col style={{ width: '25%' }}/>
      </colgroup>
      <thead>
        <tr>
          <th>Player</th>
          <th>Bet</th>
          <th>Mult.</th>
          <th>Win</th>
        </tr>
      </thead>
      <tbody>
        {children}
      </tbody>
    </table>
  )
}

function Button(props) {
  const { status, allowAutoCashOut, state, result, onAmountInc, onAmountDecr, onCashOut, onBetSet, onBetUnset, onAutoCashOutChange } = props

  switch (status) {
    case 'idle':
    case 'start':
      if (state.id) {
        return <div className="Button-cancel" onClick={onBetUnset}>Cancel</div>
      } else {
        return (
          <div>
            <div className="Button-group">
              <div className="Button-ctrl" onClick={onAmountInc}>+1</div>
              <div className="Button-ctrl" onClick={onAmountDecr}>-1</div>
              <div className="Button-bet" onClick={onBetSet}>Bet {money(state.amount)}</div>
            </div>
            {allowAutoCashOut && <AutoCashOut amount={state.autoCashOutAmount} onChange={onAutoCashOutChange}/>}
          </div>
        )
      }
    case 'run': {
      if (state.id && -1 === state.yield) {
        return <div className="Button-cashout" onClick={onCashOut}>Cashout: {money(result * state.amount)}</div>
      }

      return <div className="Button-bet disabled">Waiting...</div>
    }
    case 'stop':
      return <div className="Button-bet disabled">Waiting...</div>
    default:
      return <div className="Button-bet disabled">Waiting...</div>
  }
}

function AutoCashOut(props) {
  const { amount, onChange } = props
  const [value, setValue] = useState(() => (amount ? amount : ''))
  const disabled = null === amount

  return (
    <div className="AutoCashOut">
      <label>
        Auto Cash out
        <input defaultChecked={!disabled} type="checkbox" onChange={handleToggle}/>
      </label>
      <input type="number" value={value} disabled={disabled} onChange={handleChange} onBlur={handleBlur} />
    </div>
  )

  function handleToggle(e) {
    onChange({ autoCashOutAmount: e.target.checked ? 1.10 : null })
    setValue(e.target.checked ? '1.10' : '')
  }

  function handleChange(e) {
    setValue(e.target.value)
  }

  function handleBlur() {
    if ('' === value || '0' === value || Number(value) < 1.01) {
      onChange({ autoCashOutAmount: 1.10 })
      setValue('1.10')
    } else {
      onChange({ autoCashOutAmount: Number(value) })
    }
  }
}

function money(value, currency = null) {
  return (value / 100).toFixed(2).padEnd(2, '0') + (currency ? ' ' + currency : '')
}

export default App;